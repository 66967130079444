var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content_head"},[_c('div',{staticClass:"inner"},[_vm._m(0),_c('h2',{staticClass:"page_title"},[_vm._v("서비스 소개")]),_c('nav',{staticClass:"page_tab"},[_c('ul',{staticClass:"tab_list swiper-wrapper"},[_c('li',{staticClass:"tab_item swiper-slide",class:_vm.oneActive?'active':'',on:{"click":function($event){return _vm.goSection(1)}}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("데이터 수집")])]),_c('li',{staticClass:"tab_item swiper-slide",class:_vm.twoActive?'active':'',on:{"click":function($event){return _vm.goSection(2)}}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("데이터 저장 및 가공")])]),_c('li',{staticClass:"tab_item swiper-slide",class:_vm.threeActive?'active':'',on:{"click":function($event){return _vm.goSection(3)}}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("분석 및 레포팅")])]),_c('li',{staticClass:"tab_item swiper-slide",class:_vm.fourActive?'active':'',on:{"click":function($event){return _vm.goSection(4)}}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("교육 및 컨설팅")])])])])])]),_c('div',{staticClass:"content_body"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"service_section section_05"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"button_area"},[_c('button',{staticClass:"btn_primary",attrs:{"type":"submit"},on:{"click":_vm.goPage}},[_vm._v("서비스 신청 바로가기")]),_vm._m(5)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"breadcrumb"},[_c('ul',[_c('li',[_c('a',{staticClass:"ico_home",attrs:{"href":"#","title":"Home"}})]),_c('li',[_vm._v("서비스 소개")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service_section section_01",attrs:{"id":"section01"}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"label"},[_vm._v("데이터 수집")]),_c('strong',{staticClass:"tit"},[_vm._v(" WAPU만의"),_c('br',{staticClass:"br_575"}),_vm._v(" "),_c('b',[_vm._v("데이터 수집 서비스")]),_vm._v("를 통해"),_c('br'),_vm._v(" "),_c('b',[_vm._v("데이터를 통합")]),_vm._v("해 보세요. ")]),_c('div',{staticClass:"desc"},[_vm._v(" 흩어진 데이터로 인해 고생하신 경험이 있지 않으신가요?"),_c('br'),_vm._v(" 매일 엑셀로 수작업 하고 계시진 않으신가요?"),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" WAPU 시스템은 이런 고민을 해결해 드릴 수 있습니다."),_c('br'),_vm._v(" WAPU 시스템은 데이터 수집 역량을 총동원하여 CAFE24, 고도몰, 사방넷 등"),_c('br'),_vm._v(" 온라인 판매 채널의 매출, 상품, 고객 등의 데이터뿐만 아니라"),_c('br'),_vm._v(" 오프라인 POS 데이터, 사내 ERP 데이터, 그 외에 본인 PC에 있는 엑셀 데이터까지"),_c('br'),_vm._v(" 모두 수집하여 DB화할 수 있습니다."),_c('br'),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" 한번 개발되면 안정적으로 꾸준히 추출하여 안전하게 DB에 적재하는"),_c('br'),_vm._v(" 와이즈퓨어만의 데이터 수집 서비스를 통해 여러분 회사의 데이터를 통합해 보세요. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service_section section_02",attrs:{"id":"section02"}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"label"},[_vm._v("데이터 저장 및 가공")]),_c('strong',{staticClass:"tit"},[_vm._v(" WAPU는 "),_c('b',[_vm._v("수집한 데이터")]),_vm._v("를"),_c('br'),_vm._v(" "),_c('b',[_vm._v("분석하기 편하게 정리")]),_vm._v("하여"),_c('br',{staticClass:"br_575"}),_vm._v(" 저장합니다. ")]),_c('div',{staticClass:"desc"},[_vm._v(" “구슬이 서 말이라도 꿰어야 보배”라는 말처럼"),_c('br'),_vm._v(" WAPU 는 수집한 데이터를 분석하기 편하게 정리하여 저장합니다."),_c('br'),_vm._v(" 흩어진 데이터를 고성능 DB 한곳에 모아 저장하고 여러분의 데이터로 자산화합니다."),_c('br'),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" 이렇게 한곳에 모여진 데이터는 온라인과 오프라인 데이터와의 다양한 다차원 분석이 가능하게 가공됩니다."),_c('br'),_vm._v(" 아울러 제한 없는 저장 공간과 빠른 적재 성능으로 쾌적한 환경에서 데이터 브라우징이 가능하고 자산화했기에 "),_c('br'),_vm._v(" 언제든 사내 다른 DB로 마이그레이션하시거나 "),_c('br'),_vm._v(" R이나 태블로, 파이썬으로 접속하여 여러분의 역량에 따라 고차원 분석에 활용하실 수도 있습니다."),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service_section section_03 bg_grey",attrs:{"id":"section03"}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"label"},[_vm._v("분석 및 리포팅")]),_c('strong',{staticClass:"tit"},[_c('b',[_vm._v("잠들어있는 데이터")]),_vm._v("를 깨워"),_c('br'),_vm._v(" "),_c('b',[_vm._v("데이터로 의사 결정")]),_vm._v("할 수 있는"),_c('br',{staticClass:"br_575"}),_vm._v(" 환경을 갖춰보세요. ")]),_c('div',{staticClass:"desc"},[_vm._v(" 모아진 데이터로 채널, 고객, 상품 등 다양한 리포트를 구성해 드립니다."),_c('br'),_vm._v(" 대시보드, 매출 분석(채널별/상품별/회원별), 고객 행동분석, 예측분석, 로우 데이터 제공 등 "),_c('br'),_vm._v(" 탁월한 분석 능력과 역량으로 데이터로 의사결정할 수 있는 화면을 설계해 드리며"),_c('br'),_vm._v(" 아울러 원하시는 리포트 형태가 있다면 사전에 협의를 통해 구축도 해드립니다."),_c('br'),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" 모든 것이 여러분이 쉽게 접근 가능한 GOOGLE 계정으로 진행되어 "),_c('br'),_vm._v(" GOOGLE DATASTUDIO로 리포트를 제공해 드려 언제 어디서나 접근이 가능합니다."),_c('br'),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" 다차원 분석으로 다른 솔루션에서 제공할 수 없는 유연함과 다양함으로 "),_c('br'),_vm._v(" 여러분 사내에 잠들어있는 데이터를 깨워 매출 확대와 데이터로 의사 결정할 수 있는 환경을 갖춰보세요."),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service_section section_04",attrs:{"id":"section04"}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"label"},[_vm._v("컨설팅")]),_c('strong',{staticClass:"tit"},[_c('b',[_vm._v("컨설팅")]),_vm._v("을 통해 "),_c('b',[_vm._v("부족한 연결고리")]),_vm._v("를"),_c('br'),_vm._v(" 채워드릴 수 있습니다. ")]),_c('div',{staticClass:"desc"},[_vm._v(" 저희 와이즈앤퓨어는 빅데이터 관련 전문가로 구성된 회사로"),_c('br'),_vm._v(" 여러분 회사에 있는 데이터 결핍으로 인한 고민과 문제점을 해결해 드릴"),_c('br'),_vm._v(" 지식과 노하우를 갖추고 있습니다."),_c('br'),_vm._v(" 이를 위해 빅테이터 관련 교육 및 컨설팅이 가능합니다."),_c('br'),_c('br'),_c('br',{staticClass:"br_575"}),_vm._v(" 컨설팅을 통해 여러분에게 있는 부족한 연결고리를 채워드릴 수 있습니다. "),_c('br'),_vm._v(" (교육 및 컨설팅에는 소정의 비용이 추가됩니다.)"),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn_primary"},[_c('a',{attrs:{"href":"/wapu/assets/file/WAPU.pdf","download":""}},[_vm._v("서비스 소개 자료 다운받기")])])
}]

export { render, staticRenderFns }