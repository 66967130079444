<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>서비스 소개</li>
						</ul>
					</div>
					<h2 class="page_title">서비스 소개</h2>
					<!-- 페이지 탭 -->
					<nav class="page_tab">
						<ul class="tab_list swiper-wrapper">
							<!-- 해당 페이지의 탭에 active 클래스 추가 -->
							<li class="tab_item swiper-slide" :class="oneActive?'active':''" @click="goSection(1)"><a href="javascript:;">데이터 수집</a></li>
							<li class="tab_item swiper-slide" :class="twoActive?'active':''" @click="goSection(2)"><a href="javascript:;">데이터 저장 및 가공</a></li>
							<li class="tab_item swiper-slide" :class="threeActive?'active':''" @click="goSection(3)"><a href="javascript:;">분석 및 레포팅</a></li>
							<li class="tab_item swiper-slide" :class="fourActive?'active':''" @click="goSection(4)"><a href="javascript:;">교육 및 컨설팅</a></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="content_body">
				<div id="section01" class="service_section section_01">
					<div class="inner">
						<span class="label">데이터 수집</span>
						<strong class="tit">
							WAPU만의<br class="br_575"> <b>데이터 수집 서비스</b>를 통해<br> <b>데이터를 통합</b>해 보세요.
						</strong>
						<div class="desc">
							흩어진 데이터로 인해 고생하신 경험이 있지 않으신가요?<br>
							매일 엑셀로 수작업 하고 계시진 않으신가요?<br><br class="br_575">
							WAPU 시스템은 이런 고민을 해결해 드릴 수 있습니다.<br>
							WAPU 시스템은 데이터 수집 역량을 총동원하여 CAFE24, 고도몰, 사방넷 등<br>
							온라인 판매 채널의 매출, 상품, 고객 등의 데이터뿐만 아니라<br>
							오프라인 POS 데이터, 사내 ERP 데이터, 그 외에 본인 PC에 있는 엑셀 데이터까지<br>
							모두 수집하여 DB화할 수 있습니다.<br>
							<br><br class="br_575">
							한번 개발되면 안정적으로 꾸준히 추출하여 안전하게 DB에 적재하는<br>
							와이즈퓨어만의 데이터 수집 서비스를 통해 여러분 회사의 데이터를 통합해 보세요.
						</div>
					</div>
				</div>
				<div id="section02" class="service_section section_02">
					<div class="inner">
						<span class="label">데이터 저장 및 가공</span>
						<strong class="tit">
							WAPU는 <b>수집한 데이터</b>를<br> <b>분석하기 편하게 정리</b>하여<br class="br_575"> 저장합니다.
						</strong>
						<div class="desc">
							“구슬이 서 말이라도 꿰어야 보배”라는 말처럼<br>
							WAPU 는 수집한 데이터를 분석하기 편하게 정리하여 저장합니다.<br>
							흩어진 데이터를 고성능 DB 한곳에 모아 저장하고 여러분의 데이터로 자산화합니다.<br>
							<br><br class="br_575">
							이렇게 한곳에 모여진 데이터는 온라인과 오프라인 데이터와의 다양한 다차원 분석이 가능하게 가공됩니다.<br>
							아울러 제한 없는 저장 공간과 빠른 적재 성능으로 쾌적한 환경에서 데이터 브라우징이 가능하고 자산화했기에 <br>
							언제든 사내 다른 DB로 마이그레이션하시거나 <br>
							R이나 태블로, 파이썬으로 접속하여 여러분의 역량에 따라 고차원 분석에 활용하실 수도 있습니다.<br>
						</div>
					</div>
				</div>
				<div id="section03" class="service_section section_03 bg_grey">
					<div class="inner">
						<span class="label">분석 및 리포팅</span>
						<strong class="tit">
							<b>잠들어있는 데이터</b>를 깨워<br> <b>데이터로 의사 결정</b>할 수 있는<br class="br_575"> 환경을 갖춰보세요.
						</strong>
						<div class="desc">
							모아진 데이터로 채널, 고객, 상품 등 다양한 리포트를 구성해 드립니다.<br>
							대시보드, 매출 분석(채널별/상품별/회원별), 고객 행동분석, 예측분석, 로우 데이터 제공 등 <br>
							탁월한 분석 능력과 역량으로 데이터로 의사결정할 수 있는 화면을 설계해 드리며<br>
							아울러 원하시는 리포트 형태가 있다면 사전에 협의를 통해 구축도 해드립니다.<br>
							<br><br class="br_575">
							모든 것이 여러분이 쉽게 접근 가능한 GOOGLE 계정으로 진행되어 <br>
							GOOGLE DATASTUDIO로 리포트를 제공해 드려 언제 어디서나 접근이 가능합니다.<br>
							<br><br class="br_575">
							다차원 분석으로 다른 솔루션에서 제공할 수 없는 유연함과 다양함으로 <br>
							여러분 사내에 잠들어있는 데이터를 깨워 매출 확대와 데이터로 의사 결정할 수 있는 환경을 갖춰보세요.<br>
						</div>
					</div>
				</div>
				<div id="section04" class="service_section section_04">
					<div class="inner">
						<span class="label">컨설팅</span>
						<strong class="tit">
							<b>컨설팅</b>을 통해 <b>부족한 연결고리</b>를<br> 채워드릴 수 있습니다.
						</strong>
						<div class="desc">
							저희 와이즈앤퓨어는 빅데이터 관련 전문가로 구성된 회사로<br>
							여러분 회사에 있는 데이터 결핍으로 인한 고민과 문제점을 해결해 드릴<br>
							지식과 노하우를 갖추고 있습니다.<br>
							이를 위해 빅테이터 관련 교육 및 컨설팅이 가능합니다.<br>
							<br><br class="br_575">
							컨설팅을 통해 여러분에게 있는 부족한 연결고리를 채워드릴 수 있습니다. <br>
							(교육 및 컨설팅에는 소정의 비용이 추가됩니다.)<br>
						</div>
					</div>
				</div>
				<div class="service_section section_05">
					<div class="inner">
						<div class="button_area">
							<button type="submit" @click="goPage" class="btn_primary">서비스 신청 바로가기</button>
							<button class="btn_primary"><a href="/wapu/assets/file/WAPU.pdf" download>서비스 소개 자료 다운받기</a></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "serviceInfo",
	data() {
		return {
			oneActive: true,
			twoActive: false,
			threeActive: false,
			fourActive: false
		}
	},
	mounted() {
		this.$eventBus.$emit("initApp");
	},
	methods: {
		goPage() {
			this.$router.push("/service/info")
		},
		goSection(num) {
			switch (num) {
				case 1:
					this.oneActive= true
					this.twoActive= false
					this.threeActive= false
					this.fourActive= false
					document.getElementById('section01').scrollIntoView();
					break;
				case 2:
					this.oneActive= false
					this.twoActive= true
					this.threeActive= false
					this.fourActive= false
					document.getElementById('section02').scrollIntoView();
					break;
				case 3:
					this.oneActive= false
					this.twoActive= false
					this.threeActive= true
					this.fourActive= false
					document.getElementById('section03').scrollIntoView();
					break;
				case 4:
					this.oneActive= false
					this.twoActive= false
					this.threeActive= false
					this.fourActive= true
					document.getElementById('section04').scrollIntoView();
					break;
			}

		}
	}
}
</script>

<style scoped>

</style>